<template>
  <div class="pageContent">
    <noNavHeader title="买家中心" />
    <div class="matching">
      <el-row :gutter="15">
        <el-col :span="3">
          <el-col :span="24">
            <div class="matchingLeft">
              <!-- <div>
                <p class="matchingLeftTitle">挂摘牌</p>
              </div>
              <router-link to="/matching/qiugou">
                <p class="matchingLeftItem">我的求购</p>
              </router-link>
              <router-link to="/matching/order">
                <p class="matchingLeftItem">我的订单</p>
              </router-link>
              <router-link to="/matching/dealOrder">
                <p class="matchingLeftItem">我的成交</p>
              </router-link>
              <router-link to="/matching/holdProduct">
                <p class="matchingLeftItem">我的持仓</p>
              </router-link>
              <div>
                <p class="matchingLeftTitle">产品预售</p>
              </div>
              <router-link to="/matching/buyAgreeList">
                <p class="matchingLeftItem">产能采购协议</p>
              </router-link>
              <router-link to="/matching/pickGoods">
                <p class="matchingLeftItem">我要提货</p>
              </router-link>
              <router-link to="/matching/pickGoodsList">
                <p class="matchingLeftItem">提货查询</p>
              </router-link>
              </div>-->
              <div class="mainTitle">
                <p>买家中心</p>
              </div>
              <div>
                <p class="matchingLeftTitle">采购委托</p>
              </div>
              <router-link to="/buyerCenter/buy/spotBuy">
                <p class="matchingLeftItem">现货采购委托</p>
              </router-link>
              <router-link to="/buyerCenter/buy/spotBuyOrder">
                <p class="matchingLeftItem">
                  现货采购订单
                  <span class="elTag" v-if="spotResTotal">{{ spotResTotal }}</span>
                </p>
              </router-link>
              <router-link to="/buyerCenter/buy/biddingBuy">
                <p class="matchingLeftItem">竞价采购委托</p>
              </router-link>
              <router-link to="/buyerCenter/buy/biddingBuyOrder">
                <p class="matchingLeftItem">
                  竞价采购订单
                  <span class="elTag" v-if="biddingResTotal">{{ biddingResTotal }}</span>
                </p>
              </router-link>
              <router-link to="/buyerCenter/buy/buyInfo">
                <p class="matchingLeftItem">求购信息发布</p>
              </router-link>
              <div>
                <p class="matchingLeftTitle">买入订单</p>
              </div>
              <router-link to="/buyerCenter/purchase/spotOrder">
                <p class="matchingLeftItem fourTextItem">
                  现货订单
                  <span class="elTag" v-if="spotTotal">{{ spotTotal }}</span>
                </p>
              </router-link>
              <router-link to="/buyerCenter/purchase/preSellOrder">
                <p class="matchingLeftItem fourTextItem">
                  预售订单
                  <span class="elTag" v-if="preSellTotal">{{ preSellTotal }}</span>
                </p>
              </router-link>
              <router-link to="/buyerCenter/purchase/biddingOrder">
                <p class="matchingLeftItem fourTextItem">
                  竞价订单
                  <span class="elTag" v-if="biddingTotal">{{ biddingTotal }}</span>
                </p>
              </router-link>
              <router-link to="/buyerCenter/purchase/basisOrder" v-if="isShowBaise">
                <p class="matchingLeftItem fourTextItem">
                  基差订单
                  <span class="elTag" v-if="basisTotal">{{ basisTotal }}</span>
                </p>
              </router-link>
              <router-link to="/buyerCenter/purchase/basisBuybackOrder" v-if="isShowBaise">
                <p class="matchingLeftItem">
                  基差回购订单
                  <span class="elTag" v-if="basisBackTotal">{{ basisBackTotal }}</span>
                </p>
              </router-link>
              <div>
                <p class="matchingLeftTitle">采购管理</p>
              </div>
              <router-link to="/buyerCenter/purchase/contract">
                <p class="matchingLeftItem">我的合同</p>
              </router-link>
              <router-link to="/buyerCenter/purchase/registeredBidding">
                <p class="matchingLeftItem">已报名竞价</p>
              </router-link>
              <!-- <router-link to="/buyerCenter/purchase/winningBidding">
                <p class="matchingLeftItem">中标竞价销售</p>
              </router-link>-->
              <!-- <div>
                <p class="matchingLeftTitle">交收管理</p>
              </div>-->
              <!-- <router-link to="/buyerCenter/logistics/myPosition">
                <p class="matchingLeftItem">我的持仓</p>
              </router-link>-->
              <!-- <router-link to="/buyerCenter/logistics/pickupGoods">
                <p class="matchingLeftItem">买入仓单</p>
              </router-link>-->
              <!-- <router-link to="/buyerCenter/logistics/selfBill">
                <p class="matchingLeftItem">自提查询</p>
              </router-link>
              <router-link to="/buyerCenter/logistics/logisticsBill">
                <p class="matchingLeftItem">物流查询</p>
              </router-link>-->
            </div>
          </el-col>
        </el-col>
        <el-col :span="21">
          <div class="matchingRight">
            <router-view />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import noNavHeader from "../base/header/noNavHeader";
export default {
  data() {
    return {
      tab: 1,
      spotTotal: 0,
      biddingTotal: 0,
      preSellTotal: 0,
      spotResTotal: 0,
      biddingResTotal: 0,
      basisBackTotal: 0,
      basisTotal: 0,
      isShowBaise: window.g.isShowBaise
    };
  },
  components: {
    noNavHeader
  },
  methods: {
    change(item) {
      this.tab = item;
    },
    // 买入订单>现货订单
    getSpotTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        pickFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
        defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        sourceId: "W",
        hangResourceType: ["X"]
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.spotTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 买入订单>竞价订单
    getBiddingTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        pickFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
        defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        sourceId: "J",
        hangResourceType: ["X", "B"]
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.biddingTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 买入订单>预售订单
    getPreSellTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        pickFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        pickResourcesStatus: ["W", "T"],
        sourceId: "C",
        hangResourceType: ["B"]
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.preSellTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 现货订单
    getSpotResTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        pickResourcesStatus: ["W", "T"],
        sourceId: "W",
        hangResourceType: ["Q"]
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.spotResTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 竞价订单
    getBiddingResTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
        defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        hangResourceType: ["Q"],
        sourceId: "J"
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.biddingResTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 基差订单
    getBasisTotal() {
      let params = {
        page: 0,
        size: 10,
        pickFirmId: sessionStorage.getItem("userFirmId"),
        pickResourcesStatus: ["W"]
      };
      protocolFwd.param_queryBasisQueryOrderLd.param = params;
      http
        .postFront(protocolFwd.param_queryBasisQueryOrderLd)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.totalElements) {
              this.basisTotal = value.totalElements;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 基差回购订单
    getBasisBackTotal() {
      let params = {
        page: 0,
        size: 10,
        bsFlag: "F",
        repoStatus: ["W"],
        repoType: "M"
      };
      protocolFwd.param_queryBasisQueryRepoOrderLd.param = params;
      http
        .postFront(protocolFwd.param_queryBasisQueryRepoOrderLd)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.totalElements) {
              this.basisBackTotal = value.totalElements;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    }
  },
  mounted() {
    this.getSpotTotal();
    this.getBiddingTotal();
    this.getPreSellTotal();
    this.getSpotResTotal();
    this.getBiddingResTotal();
    if(this.isShowBaise){
      this.getBasisBackTotal();
      this.getBasisTotal();
  
    }
  }
};
</script>
<style lang="scss" scoped>
.matching {
  width: 80%;
  min-width: 1300px;
  min-height: 1000px;
  margin: 0 auto;
  // background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.matching .matchingLeft {
  line-height: 40px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.matching .matchingLeft .mainTitle {
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: $commonUserTitleColor;
}
.matching .matchingLeft .mainTitle p {
  font-size: 16px;
}
.matching .matchingLeft .matchingLeftTitle {
  padding-left: 30px;
  font-size: 16px;
  font-weight: 700;
}
.matching .matchingLeft .matchingLeftItem {
  padding-left: 30px;
  font-size: 14px;
  color: #333333;
  position: relative;
}
.matching .matchingRight {
  padding: 15px 0;
  min-height: 1000px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.router-link-active p.matchingLeftItem {
  color: $commonThemeColor !important;
}
.matching .matchingLeft .matchingLeftItem .elTag {
  position: absolute;
  left: 107px;
  top: 0px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  color: red;
  border: 1px solid red;
  line-height: 18px;
  text-align: center;
  -webkit-transform: scale(0.75);
}
.matching .matchingLeft .fourTextItem .elTag {
  left: 80px;
}
</style>
